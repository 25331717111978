import { Button } from 'hult-toolkit';
import { StyledQuestionContainer } from '../../QuestionForm/questionForm.styles';
import { StyledQuestionWrapper } from '../../Steps/steps.styles';
import { Input } from '../../ui/inputs/input';
import { StyledCheckValidation, StyledCrossValidation } from '../../ui/inputs/inputs.styles';
import { ILoadingInput } from './TextInput.types';

// Validation Icon
export const LookUpValidationIcon = (valid?: boolean) => {
  if (valid === true) {
    return <StyledCheckValidation icon="check-circle" />;
  }

  if (valid === false) {
    return <StyledCrossValidation icon="times-circle" />;
  }
};

export const LoadingInput = ({ loadingButton }: ILoadingInput) => {
  return (
    <StyledQuestionWrapper>
      {loadingButton &&
        <Button
          icon={{
            animate: 'spin',
            library: 'solid',
            name: 'spinner',
          }}
          label="Loading"
          size="medium"
          variant="disabled"
        />}
      <StyledQuestionContainer>
        <Input type="text" label="Loading..." loading={true} />
      </StyledQuestionContainer>
      <Button
        icon={{
          animate: 'spin',
          library: 'solid',
          name: 'spinner',
        }}
        label="Loading"
        size="medium"
        variant="disabled"
      />
    </StyledQuestionWrapper>
  );
};
